/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/tailwind.css"
import { getLangAttribute } from "./src/constants/locale.constants"

const onRouteUpdate = ({ location }) => {
    const { pathname } = location
    document.documentElement.lang = getLangAttribute(pathname)
    handleScrollToSection()
}

const shouldUpdateScroll = ({ routerProps: { location } }) => {
    const { hash } = location
    if (hash) {
        return false
    }
}

const handleScrollToSection = () => {
    const { hash } = window.location
    if (hash) {
        const id = hash.replace("#", "")
        const element = document.getElementById(id)
        if (element) {
            const header = document.querySelector("header")
            const headerHeight = header ? header.offsetHeight : 0
            setTimeout(() => {
                window.scrollTo({
                    top: element.offsetTop - headerHeight,
                    behavior: "smooth",
                })
                window.history.replaceState(null, null, window.location.pathname)
            }, 100)
        }
    }
}

export { onRouteUpdate, shouldUpdateScroll }
